import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '../tools/withStyles';
import { Main } from '../components/Main';
import { Button } from '../components/Button';
import { Text } from '../components/Text';
import { Fader } from '../components/Fader';
import { Secuence } from '../components/Secuence';
import { Link } from '../components/Link';
import { ArwesThemeProvider, Figure, StylesBaseline, List, Table, AnimatorGeneralProvider, FrameHexagon } from '@arwes/core';

const styles = theme => ({
  root: {}
});

class Tvm extends React.Component {
  static propTypes = {
    classes: PropTypes.object
  };

  render() {
    const { classes } = this.props;

    const headers = [
      { id: 1, data: '$BAG Number' },
      { id: 1, data: 'Genesis Toodle' }
    ];
    const dataset = [{
      id: 1,
      columns: [
        { id: 1, data: '1' },
        { id: 1, data: 'Coming Soon!'}
      ],
    }, {
      id: 2,
      columns: [
        { id: 2, data: '2' },
        { id: 2, data: 'Coming Soon!' }
      ],
    },
    {
      id: 3,
      columns: [
        { id: 3, data: '3' },
        { id: 3, data: 'Coming Soon!' }
      ],
  },
  {
    id: 4,
    columns: [
      { id: 4, data: '4' },
      { id: 4, data: 'Coming Soon!' }
    ],
},
{
  id: 5,
  columns: [
    { id: 5, data: '5' },
    { id: 5, data: 'Coming Soon!' }
  ],
}];

    return (
      <Main className={classes.root}>
        <Secuence stagger>
          <ArwesThemeProvider>
            <article>
              <Fader>
                <header>
                  <h1><Text>Toodle's $L2</Text></h1>
                </header>
                <div>
                  <FrameHexagon style={{ margin: [0, 'auto'], height: 'auto', padding: 15, width: '656x', textAlign: 'left' }} hover>
                    <div style={{ width: 200, height: 5 }} />
                        <p>
                          The Toodle Virtual Machine is a Layer 2 zkEVM Rollup that executes both Bitcoin & Ethereum smart contracts. It is the primary home for all Toodles.<br />
                        </p>
                  </FrameHexagon>
                </div>
                <br/>
                <div style={{ width: '656x', textAlign: 'left' }}>
                <p>
                    The dual compatibility of the Bitcoin and Ethereum blockchains within the Toodle Virtual Machine allows Toodles to use native BTC & ETH digital assets. This includes:<br />
                    <ul>
                      <li>BRC-20 and ERC-20 tokens</li>
                      <li>BRC-721 and ERC-721 tokens</li>
                      <li>Bitcoin Ordinals</li>
                    </ul>
                    Easily build, bridge, and atomically swap these native BTC & ETH digital assets with other Toodles within the Toodle Virtual Machine.<br /><br />
                  </p>
                </div>
                <header>
                  <h1>Layer 2 Launch Coming Soon!</h1>
                </header>
                  <p>
                    Bookmark this page for details about our Layer 2 launch for the Toodle Virtual Machine.<br /><br />
                    Toodle Virtual Machine's testnet will be deployed prior to the mainnet launch.<br /><br />
                    Please be aware of scams and only trust information about Cryptoodles through our official links.<br />
                  </p>
              <header>
                <h1><Text>Disclaimer</Text></h1>
              </header>
              <p>
                This blockchain project is conceptual. Mint at your own risk.
              </p>
              </Fader>
            </article>
          </ArwesThemeProvider>
        </Secuence>
      </Main>
    );
  }
}

export default withStyles(styles)(Tvm);
